import React from 'react';
import StrawberryLogo from '../assets/logo-strawberry.png';

// const Logo = () => (
//     <img src={StrawberryLogo} alt="Strawberry Logo" className="w-16 h-16 text-center" />
// );

const Logo = ({ size = 'small' }) => {
    const sizeClasses = {
      small: 'w-6 h-6',
      medium: 'w-8 h-8',
      large: 'w-16 h-16'
    };
  
    return (
      <img 
        src={StrawberryLogo} 
        alt="Strawberry Logo" 
        className={`${sizeClasses[size]} text-center`} 
      />
    );
};

export default Logo;