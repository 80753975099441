import OpenAILogoDark from '../assets/logo-openai-dark.svg';
import OpenAILogoLight from '../assets/logo-openai-light.svg';
import ClaudeLogoDark from '../assets/logo-claude-dark.svg';
import ClaudeLogoLight from '../assets/logo-claude-light.svg';
import GeminiLogo from '../assets/logo-gemini-dark.png';
import StrawberryLogo from '../assets/logo-strawberry.png';
import MistralLogo from '../assets/logo-mistral.png';

export const getModelInfo = (darkMode) => [
    {
        id: 'claude-3-5-sonnet-20241022',
        versions: ['claude-3-5-sonnet-20241022', 'claude-3-5-sonnet-20240620'],
        name: 'Claude Sonnet 3.5',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s most intelligent model, excels in analysis and coding tasks.',
        strength: 'Complex tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'April 2024',
        scores : {
            overall: 1268,
            overallStyle: 1258,
            hardPrompts: 1278,
            hardPromptsStyle: 1268,
            instructionFollowing: 1280,
            coding: 1295,
            math: 1272,
            multiTurn: 1306,
            longerQuery: 1287
        },
        cost: 10,
        speed: 7,
        hidden: false
    },
    {
        id: 'gpt-4o',
        versions: ['gpt-4o'],
        name: 'GPT-4o',
        logo: darkMode ? OpenAILogoLight : OpenAILogoDark,
        developer: 'OpenAI',
        description: 'OpenAI\'s high-intelligence flagship model for complex, multi-step tasks',
        strength: 'Complex tasks',
        contextWindow: '128k',
        parameters: '175B parameters',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'October 2023',
        scores : {
            overall: 1336,
            overallStyle: 1294,
            hardPrompts: 1331,
            hardPromptsStyle: 1280,
            instructionFollowing: 1337,
            coding: 1339,
            math: 1273,
            multiTurn: 1371,
            longerQuery: 1361
        },
        cost: 10,
        speed: 9,
        hidden: false
    },
    {
        id: 'gemini-1.5-flash',
        versions: ['gemini-1.5-flash'],
        name: 'Gemini 1.5 Flash',
        logo: GeminiLogo,
        developer: 'Google',
        description: 'Google\'s fastest, most cost-efficient multimodal model with great performance for high-frequency tasks',
        strength: 'Quick, simple tasks',
        contextWindow: 'unknown',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'May 2024',
        scores : {
            overall: 1268,
            overallStyle: 1235,
            hardPrompts: 1262,
            hardPromptsStyle: 1234,
            instructionFollowing: 1264,
            coding: 1257,
            math: 1242,
            multiTurn: 1283,
            longerQuery: 1309
        },
        cost: 10,
        speed: 9,
        hidden: false
    },
    {
        id: 'claude-3-opus-20240229',
        versions: ['claude-3-opus-20240229'],
        name: 'Claude Opus 3',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s powerful model for highly complex tasks',
        strength: 'Complex tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'August 2023',
        scores : {
            overall: 1247,
            overallStyle: 1237,
            hardPrompts: 1245,
            hardPromptsStyle: 1234,
            instructionFollowing: 1257,
            coding: 1250,
            math: 1239,
            multiTurn: 1278,
            longerQuery: 1269
        },
        cost: 14,
        speed: 4,
        hidden: true
    },
    {
        id: 'claude-3-sonnet-20240229',
        versions: ['claude-3-sonnet-20240229'],
        name: 'Claude Sonnet 3',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s model balancing intelligence and speed',
        strength: 'Complex tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'August 2023',
        scores : {
            overall: 1201,
            overallStyle: 1196,
            hardPrompts: 1199,
            hardPromptsStyle: 1190,
            instructionFollowing: 1204,
            coding: 1213,
            math: 1181,
            multiTurn: 1226,
            longerQuery: 1222
        },
        cost: 10,
        speed: 7,
        hidden: true
    },
    {
        id: 'claude-3-haiku-20240307',
        versions: ['claude-3-haiku-20240307'],
        name: 'Claude Haiku 3',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s fastest and most compact model for near-instant responsiveness',
        strength: 'Quick, simple tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'August 2023',
        scores : {
            overall: 1178,
            overallStyle: 1176,
            hardPrompts: 1177,
            hardPromptsStyle: 1173,
            instructionFollowing: 1181,
            coding: 1188,
            math: 1158,
            multiTurn: 1191,
            longerQuery: 1197
        },
        cost: 6,
        speed: 9,
        hidden: true
    },
    {
        id: 'claude-3-5-haiku-20241022',
        versions: ['claude-3-5-haiku-20241022'],
        name: 'Claude Haiku 3.5',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s fastest and most compact model for near-instant responsiveness',
        strength: 'Quick, simple tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'August 2023',
        scores : {
            overall: 0,
            overallStyle: 0,
            hardPrompts: 0,
            hardPromptsStyle: 0,
            instructionFollowing: 0,
            coding: 0,
            math: 0,
            multiTurn: 0,
            longerQuery: 0
        },
        cost: 6,
        speed: 9,
        hidden: false
    },
    {
        id: 'gpt-4o-mini',
        versions: ['gpt-4o-mini'],
        name: 'GPT-4o mini',
        logo: darkMode ? OpenAILogoLight : OpenAILogoDark,
        developer: 'OpenAI',
        description: 'OpenAI\'s affordable and intelligent small model for fast, lightweight tasks',
        strength: 'Quick, simple tasks',
        contextWindow: '128k',
        parameters: '175B parameters',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'October 2023',
        scores : {
            overall: 1272,
            overallStyle: 1229,
            hardPrompts: 1267,
            hardPromptsStyle: 1218,
            instructionFollowing: 1265,
            coding: 1283,
            math: 1225,
            multiTurn: 1293,
            longerQuery: 1295
        },
        cost: 6,
        speed: 9,
        hidden: false
    },
    {
        id: 'gpt-4-turbo',
        versions: ['gpt-4-turbo'],
        name: 'GPT-4 Turbo',
        logo: darkMode ? OpenAILogoLight : OpenAILogoDark,
        developer: 'OpenAI',
        description: 'OpenAI\'s latest GPT-4 Turbo model with vision capabilities',
        strength: 'Quick, simple tasks',
        contextWindow: '128k',
        parameters: '125B parameters',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'October 2023',
        scores : {
            overall: 1256,
            overallStyle: 1240,
            hardPrompts: 1254,
            hardPromptsStyle: 1226,
            instructionFollowing: 1261,
            coding: 1263,
            math: 1242,
            multiTurn: 1267,
            longerQuery: 1265
        },
        cost: 10,
        speed: 8,
        hidden: true
    },
    {
        id: 'gemini-1.5-pro',
        versions: ['gemini-1.5-pro'],
        name: 'Gemini 1.5 Pro',
        logo: GeminiLogo,
        developer: 'Google',
        description: 'Google\'s best performing multimodal model with features for a wide variety of reasoning tasks',
        strength: 'Complex tasks',
        contextWindow: 'unknown',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'May 2024',
        scores : {
            overall: 1299,
            overallStyle: 1266,
            hardPrompts: 1287,
            hardPromptsStyle: 1259,
            instructionFollowing: 1293,
            coding: 1286,
            math: 1272,
            multiTurn: 1306,
            longerQuery: 1322
        },
        cost: 10,
        speed: 7,
        hidden: false
    },
    {
        id: 'gemini-1.0-pro',
        versions: ['gemini-1.0-pro'],
        name: 'Gemini 1.0 Pro',
        logo: GeminiLogo,
        developer: 'Google',
        description: 'Google\'s legacy model - precursor to Gemini 1.5',
        strength: 'Complex tasks',
        contextWindow: 'unknown',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'February 2024',
        scores : {
            overall: 1110,
            overallStyle: 1131,
            hardPrompts: 1083,
            hardPromptsStyle: 1115,
            instructionFollowing: 1103,
            coding: 1091,
            math: 1085,
            multiTurn: 1143,
            longerQuery: 1125
        },
        cost: 10,
        speed: 6,
        hidden: true
    },
    {
        id: 'web-search',
        versions: ['web-search'],
        name: 'Searchy-1',
        logo: StrawberryLogo,
        developer: 'Strawberry',
        description: 'Our search model, powered by GPT-4o mini. Use this model only when you need up-to-date information from the internet.',
        strength: 'Web search',
        contextWindow: 'unknown',
        parameters: 'unknown',
        multilingual: false,
        vision: false,
        imageGeneration: false,
        search: true,
        trainingDataCutoff: 'Real-time web data',
        scores : {
            overall: 0,
            overallStyle: 0,
            hardPrompts: 0,
            hardPromptsStyle: 0,
            instructionFollowing: 0,
            coding: 0,
            math: 0,
            multiTurn: 0,
            longerQuery: 0
        },
        cost: 20,
        speed: 3,
        hidden: false
    },
    {
        id: 'mistral-small-latest',
        versions: ['mistral-small-latest'],
        name: 'Mistral Small',
        logo: MistralLogo,
        developer: 'Mistral',
        description: 'Mistral\'s small model, with a focus on fast, lightweight tasks.',
        strength: 'Quick, simple tasks',
        contextWindow: '32k',
        parameters: '22B parameters',
        multilingual: false,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        scores : {
            overall: 0,
            overallStyle: 0,
            hardPrompts: 0,
            hardPromptsStyle: 0,
            instructionFollowing: 0,
            coding: 0,
            math: 0,
            multiTurn: 0,
            longerQuery: 0
        },
        cost: 6,
        speed: 7,
        hidden: false
    },
    {
        id: 'mistral-large-latest',
        versions: ['mistral-large-latest'],
        name: 'Mistral Large',
        logo: MistralLogo,
        developer: 'Mistral',
        description: 'Mistral\'s large model, with a focus on complex tasks.',
        strength: 'Complex tasks',
        contextWindow: '128k',
        parameters: 'unknown',
        multilingual: false,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        scores : {
            overall: 0,
            overallStyle: 0,
            hardPrompts: 0,
            hardPromptsStyle: 0,
            instructionFollowing: 0,
            coding: 0,
            math: 0,
            multiTurn: 0,
            longerQuery: 0
        },
        cost: 14,
        speed: 7,
        hidden: false
    },
    {
        id: 'pixtral-12b-2409',
        versions: ['pixtral-12b-2409'],
        name: 'Pixtral 12B',
        logo: MistralLogo,
        developer: 'Mistral',
        description: 'Mistral\'s 12B model, with image understanding.',
        strength: 'Complex tasks',
        contextWindow: '128k',
        parameters: '12B parameters',
        multilingual: false,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        scores : {
            overall: 0,
            overallStyle: 0,
            hardPrompts: 0,
            hardPromptsStyle: 0,
            instructionFollowing: 0,
            coding: 0,
            math: 0,
            multiTurn: 0,
            longerQuery: 0
        },
        cost: 10,
        speed: 7,
        hidden: true
    },
    {
        id: 'ministral-3b-latest',
        versions: ['ministral-3b-latest'],
        name: 'Ministral 3B',
        logo: MistralLogo,
        developer: 'Mistral',
        description: 'Mistral\'s 3B model, which they call the world\'s best edge model.',
        strength: 'Quick, simple tasks',
        contextWindow: '128k',
        parameters: '3B parameters',
        multilingual: false,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        scores : {
            overall: 0,
            overallStyle: 0,
            hardPrompts: 0,
            hardPromptsStyle: 0,
            instructionFollowing: 0,
            coding: 0,
            math: 0,
            multiTurn: 0,
            longerQuery: 0
        },
        cost: 6,
        speed: 7,
        hidden: true
    }
];